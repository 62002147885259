@use '@carbon/styles/scss/type' as *;
@use '@carbon/react/scss/breakpoint' as *;
@use '@carbon/layout';

.article-tile {
    height: 100%;
    min-height: 200px;
    flex-basis: 100%;
    // text-align: center;
    // border: 1px solid black;
}

.article_tile_header {
    @include type-style('fluid-heading-03', true);
    margin-bottom: layout.$spacing-05;
}

.article_tile_content {
    @include type-style('body-01', true);
}

.article_link {
    @include type-style('body-compact-01', true);
}

.time_from_publish {
    @include type-style('legal-01', true);
    margin-left: 3px;
}

.helper_text_02 {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.16px;
    color: #161616;
}
