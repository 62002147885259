@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/config' as *;

.create-app-modal-header {
    @include type-style('productive-heading-03', true);
    margin-bottom: 1rem;
}

.#{$prefix}--text-input__invalid-icon {
    top: 35%;
}