@use '@carbon/styles/scss/type' as *;

.upgrade_info_header {
    @include type-style('fluid-heading-03', true);
    margin-bottom: 1rem;
}

.productive_body_01 {
    @include type-style('body-01', true);
}

.fluid_header_04 {
    @include type-style('fluid-heading-04', true);
}