@use '@carbon/styles/scss/type' as *;
@use '@carbon/styles/scss/config' as *;

.productive_heading_03_font {
    @include type-style('productive-heading-03', true);
}

.legal_01_font {
    @include type-style('legal-01');
}

.body_compact_01_font {
    @include type-style('body-compact-01');
}

.body_01_font {
    @include type-style('body-01');
}

.fluid_heading_03 {
    @include type-style('fluid-heading-03', true);
}

.label_02_font {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #A8A8A8
}