@use '@carbon/react/scss/breakpoint' as *;

@include breakpoint-down(md) {
    .powered-by-stripe {
        flex-wrap: wrap;
    }
}

@include breakpoint-down(lg) {
    .stripe-payment-form-column {
        order: 2
    }

    .upgrade-account-info-column {
        order: 1
    }
}